//Copied from study-com. Definitely need to make an artifactory lib for this.
//Be careful not to import or export any dependencies in this file, it load/runs before requirejs

interface ErrorInfo {
	lineNumber?: string;
	message?: string;
	name?: string;
	sourceJavascript?: string;
	stack?: string;
	type?: string;
}

interface LoggableEvent {
	[x: string]: any;
	
	eventType?: string;
	javascriptTimestamp?: number;
	javascriptUUID?: string;
	lineNumber?: string;
	message?: string;
	name?: string;
	pageImpressionGuid?: string;
	pageRequestGuid?: string;
	sourceJavascript?: string;
	stacktrace?: string;
	type?: string;
	url?: string;
}

class GlobalUtils implements IGlobalUtils {
	private blackListedErrorLimit: number = 3;
	public logLimit: number = 100;
	private sentErrorMap: {[key: string]: boolean} = {};
	//NOTE: code to insert this into the DOM was not copied over to microsites. This approach seems equivalent in function.
	private pageImpressionGuid: string = this.generateUUID();
	
	public getRequestGuid(): string {
		let requestGuidElement = document.querySelector("meta[name=requestGuid]");
		let requestGuid;
		if (typeof requestGuidElement === 'undefined' || !requestGuidElement) {
			requestGuidElement = document.getElementById("requestGuid");
			requestGuid = requestGuidElement ? (requestGuidElement as any).value : "";
		}
		else {
			requestGuid = requestGuidElement.getAttribute("content");
		}
		return requestGuid;
	}
	
	/**
	 * UUID for this page impression (client side), generated outside of this scope, found in $('#impressionGuid').val();
	 * @type {Element}
	 */
	public getPageImpressionGuid(): string {
		let impressionGuid = document.querySelector("meta[name=impressionGuid]")?.getAttribute("content");
		if (impressionGuid) {
			return impressionGuid;
		}
		
		impressionGuid = (document.getElementById("impressionGuid") as any)?.value;
		if (impressionGuid) {
			return impressionGuid;
		}
		
		return this.pageImpressionGuid;
	}
	
	/**
	 * NOTE: Is NOT RFC4122 compliant (see: http://stackoverflow.com/a/8809472/677381) since we removed the dashes
	 * was: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
	 */
	public generateGuid(): string {
		return this.generateRandomString("xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx");
	}
	
	/**
	 * Modeled from generateGuid method but attempted to fit the UUID format so that the site would stop logging warnings
	 */
	private generateUUID(): string {
		return this.generateRandomString("xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx");
	}
	
	private generateRandomString(template: string): string {
		let d = new Date().getTime();
		return template.replace(/[xy]/g, function(c) {
			let r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
		});
	}
	
	//these are used in the script tags
	public requireConfigError(event): void {
		let exception: ErrorInfo = {};
		exception.message = "error loading require config file";
		exception.sourceJavascript = '<remilon:versionify resourceLocation="${jsHost}" path="/js/requireConfig.js" />';
		exception.lineNumber = 0 + ':' + 0;
		this.logError(exception, "fileload");
	}
	
	public requireError(event): void {
		let exception: ErrorInfo = {};
		exception.message = "require.js failed to load";
		exception.sourceJavascript = "//cdnjs.cloudflare.com/ajax/libs/require.js/2.3.2/require.min.js";
		exception.lineNumber = 0 + ':' + 0;
		this.logError(exception, "require");
	}

	public onError(message, url, lineNumber, colNumber, error): void {
		let exception: ErrorInfo = (error != null) ? error : {};
		exception.message = message;
		exception.sourceJavascript = url;
		exception.lineNumber = lineNumber;
		this.logError(exception, "windowOnError");
	}
	
	public logError(exception: ErrorInfo, type: string, mutator?: LoggableEventMutator): void {
		if (typeof exception === 'string') {
			let message = exception;
			exception = {message: message}
		}
		
		let loggableEvent: LoggableEvent = this.createLoggableEventForError();
		try {
			let urlCapture = /\((.+?):(\d+):(\d+)/g;
			let matchUrl = urlCapture.exec(exception.stack);
			if (matchUrl && matchUrl.length == 4) {
				let url = matchUrl[1];
				let line = matchUrl[2];
				let col = matchUrl[3];
				loggableEvent.sourceJavascript = url;
				loggableEvent.lineNumber = line + ':' + col;
			}
			else {
				loggableEvent.sourceJavascript = exception.sourceJavascript;
				loggableEvent.lineNumber = exception.lineNumber;
			}
		}
		catch (e) {
			loggableEvent.sourceJavascript = exception.sourceJavascript;
			loggableEvent.lineNumber = exception.lineNumber;
		}
		
		loggableEvent.message = this.truncateText(exception.message);
		loggableEvent.url = window.location.href;
		loggableEvent.name = exception.name;
		loggableEvent.stacktrace = this.truncateText(exception.stack);
		loggableEvent.type = type;
		
		if (mutator != null) {
			mutator(loggableEvent);
		}
		
		let hashCode = this.computeErrorHashCode(loggableEvent);
		
		if (this.shouldLogError(loggableEvent, hashCode)) {
			this.markErrorAsSent(loggableEvent, hashCode);
			this.handleErrorEvent(loggableEvent);
		}
	}
	
	//IMPORTANT: this method is overwritten by eventLoggingInit to queue errors for batch sending
	public handleErrorEvent(loggableEvent: LoggableEvent) {
		loggableEvent.loggerType = "javascriptError-preEventTracking";
		this.logLimit--;
		let request = new XMLHttpRequest();
		request.open("POST", "/eventLogger/eventLog.ajax");
		request.setRequestHeader("Content-Type", "application/json");
		request.send(JSON.stringify([loggableEvent]));
	}
	
	public markErrorAsSent(loggableEvent: LoggableEvent, errorHashCode: number): void {
		if (this.isBlackListedError(loggableEvent) && this.blackListedErrorLimit > 0) {
			this.blackListedErrorLimit--;
		}
		
		this.sentErrorMap[errorHashCode] = true;
	}
	
	private shouldLogError(loggableEvent: LoggableEvent, errorHashCode: number): boolean {
		if (this.logLimit <= 0) {
			return false;
		}
		
		if (this.isBlackListedError(loggableEvent) && this.blackListedErrorLimit <= 0) {
			return false;
		}
		
		if (this.sentErrorMap[errorHashCode]) {
			return false;
		}
		
		return true;
	}
	
	private isBlackListedError(loggableEvent: LoggableEvent) {
		return loggableEvent.message.match(/(script error|mismatched anonymous define)/i);
	}
	
	public truncateText(text: string, maxLength: number = 4000): string {
		return (text && text.length > maxLength) ? text.substring(0, maxLength) + "....[truncated]" : text;
	}
	
	private computeErrorHashCode(loggableEvent: LoggableEvent): number {
		let hashString: string = loggableEvent.message + " | " + loggableEvent.sourceJavascript + " | " + loggableEvent.lineNumber;
		return this.simpleHashCode(hashString);
	}
	
	private simpleHashCode(text: string): number {
		let h = 0;
		for(let i = 0; i < text.length; i++) {
			h = (h << 5) - h + text.charCodeAt(i) | 0;
		}
		return h;
	}
	
	private createLoggableEventForError(): LoggableEvent {
		return this.createLoggableEvent("javascriptError");
	}
	
	/**
	 * We intentionally keep this file dependency free so that we can capture low level errors (like if requirejs fails)
	 * As such we have to duplicate some aspect of eventLogging.ts -- it is important that these be kept in sync.
	 */
	private createLoggableEvent(eventType: string): LoggableEvent {
		let loggableEvent: LoggableEvent = {};
		
		loggableEvent.eventType = eventType;
		loggableEvent.javascriptUUID = this.generateGuid();
		loggableEvent.pageImpressionGuid = this.getPageImpressionGuid();
		loggableEvent.pageRequestGuid = this.getRequestGuid();
		loggableEvent.javascriptTimestamp = new Date().getTime();
		
		return loggableEvent;
	}
	
}

let globalUtils = new GlobalUtils();

(window as any).globalUtils = globalUtils;

//would use bind but this is more compatible
// window.onerror = globalUtils.onError.bind(globalUtils)
window.onerror = (message, url, lineNumber, colNumber, error) => {
	globalUtils.onError(message, url, lineNumber, colNumber, error);
	
	// returning true will prevent the firing on the default handler, returning false will let the default handler run...
	return false;
};
